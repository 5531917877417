@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?ltuj40');
  src:  url('../../fonts/icomoon.eot?ltuj40#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?ltuj40') format('truetype'),
    url('../../fonts/icomoon.woff?ltuj40') format('woff'),
    url('../../fonts/icomoon.svg?ltuj40#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icone-"], [class*=" icone-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icone-compte-man-circle:before {
  content: "\e909";
}
.icone-greentic:before {
  content: "\e900";
}
.icone-caisse:before {
  content: "\e901";
}
.icone-arrow-left:before {
  content: "\e902";
}
.icone-arrow-right:before {
  content: "\e903";
}
.icone-certification:before {
  content: "\e904";
}
.icone-compte-man:before {
  content: "\e905";
}
.icone-new:before {
  content: "\e906";
}
.icone-percent:before {
  content: "\e907";
}
.icone-telephone:before {
  content: "\e908";
}
